import React from "react";
import { getCookieConsentValue } from "react-cookie-consent";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      window.dataLayer.push({ errorDetails: {} });
      window.dataLayer.push({
        event: "errorCode",
        errorDetails: {
          details: error.message,
          info: error.stack,
        },
      });
    }

    /*ReactGA._gtag("event", "error", {
      error: error,
      info: info
    });*/
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
