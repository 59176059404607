import { atom, selector } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const cartItemAtom = atom({
  key: "allCartItems",
  default: [],
  effects: [localStorageEffect("cart")],
});

const cartTotalSelector = selector({
  key: "totalCarItemsNumber",
  get: ({ get }) => {
    const cart = get(cartItemAtom);
    const finalCount = cart.map((item) => {
      var count = 0;
      count = parseInt(count, 10) + parseInt(item.cardDetails.cardQuantity, 10);
      return count;
    });
    if (finalCount.length === 0) {
      return 0;
    } else {
      return finalCount.reduce((a, v) => (a = a + v), 0);
    }
  },
});

const cartItemsSelector = selector({
  key: "cartItemsTotal",
  get: ({ get }) => {
    const totalCart = get(cartItemAtom);
    return totalCart;
  },
});

export { cartItemAtom, cartTotalSelector, cartItemsSelector };
