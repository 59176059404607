export const devMode = false

function devModeCheck(item) {
  switch (item) {
    case "api":
      if (devMode === true) {
        return "http://localhost:5000";
      } else {
        return "https://oddegg-backend.herokuapp.com";
      }

    case "stripe":
      if (devMode === true) {
        return "pk_test_51N4UwHB5QQwZMvmzmXAy4JZl3CSFXG3cOQytPL7kGw2FFdyFFOW773FWvwQQa9UZxGkw1D4OyjgBSitztxEmMFxs00mIfKrbXT";
      } else {
        return "pk_live_51N4UwHB5QQwZMvmzr8rtAXfHsKIegREeCHBUOgE5Ck2BuF214hqpD3FIO6aXM6laE1kScXGAwzQTrIdwdmJW3Ukj00qTyX184r";
      }

    default:
      break;
  }
}

export default devModeCheck;
