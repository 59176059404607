import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import React from "react";

const ErrorPage = () => {
  return (
    <Container>
      <Row>
        <div className=" justify-content-center text-center align-content-center align-self-center center align-items-center">
          <img
            src="https://oddegg.s3.us-east-2.amazonaws.com/generalImages/siteIdentity/Web+Assets/404.png"
            alt="404 Page"
            style={{ width: "31vh", marginTop: "5vh", marginBottom: "5vh" }}
          />
        </div>
      </Row>
      <Row>
        <h1 className="header text-center"> Oops!!</h1>
        <div className="subheader">
          {" "}
          It seems you have found an Error, sorry for the inconvenience! Your
          error has been reported and we will get it back up soon!{" "}
        </div>
        <div className=" justify-content-center text-center align-content-center align-self-center center align-items-center mb-5">
          <a href="/" style={{ textDecoration: "none" }} className="">
            <h3
              className="header border rounded-4 w-50 m-auto mt-5"
              style={{ backgroundColor: "#FF7FA6", color: "white" }}
            >
              Back To HomePage
            </h3>
          </a>
        </div>
      </Row>
    </Container>
  );
};

export default ErrorPage;
