import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./Context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RecoilRoot } from "recoil";

import ErrorBoundary from "./Pages/ErrorBoundary";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ErrorBoundary fallback={"There has been an error"}>
          <App />
        </ErrorBoundary>
      </RecoilRoot>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </AuthContextProvider>
);
